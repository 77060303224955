import React from "react";
import ministry from "../../../assets/img/components/brand/stmdLogo.svg";
import mps from "../../../assets/img/components/network/mps-logo.png";
import fraunhofer from "../../../assets/img/components/network/frauenhofer-logo.png";
import byte from "../../../assets/img/components/network/byte.webp";


let collaborations = [
  {
    title: "byte − Bayerische Agentur für Digitales GmbH",
    description:
      "byte gestaltet aktiv und menschzentriert die digitale Transformation der öffentlichen Verwaltung des Freistaats Bayern. Sie koordiniert das Digitalschmiede Programm in Kooperation mit dem Digitalministerium.",
    alt: "byte",
    image: byte,
    link: "https://www.byte.bayern/",
  },
  {
    title: "Bayerisches Staatsministerium für Digitales",
    description:
      "Durch Staatsminister Dr. Fabian Mehring erhalten die Vorhaben Unterstützung aus oberster Instanz. Als Querschnittsressort hebelt das Staatsministerium die Potenziale in den einzelnen Ministerien.",
    alt: "staatsministerium für digitales",
    image: ministry,
    link: "https://www.stmd.bayern.de",
  },
  {
    title: "mantro product studio",
    description:
      "Deutschlands erfahrenster Company Builder hilft jetzt zukunftsorientierten Unternehmen bei der digitalen Innovation und Transformation. Das mantro product studio übernimmt die Programmleitung des Digitalschmiede-Programms!",
    alt: "mps",
    image: mps,
    link: "https://www.mantro.studio",
  },
  {
    title: "Fraunhofer FIT/ FIM",
    description:
      "Der Institutsteil Wirtschaftsinformatik des Fraunhofer FIT setzt sich für eine Welt ein, in der Digitalisierung verantwortungsvoll für Individuen, Wirtschaft und Gesellschaft eingesetzt wird.  Er ist mit seinem umfassenden Forschungs- und Praxisnetzwerk für das Recruiting des Digitalschmiede-Programms zuständig und unterstützt bei der Betreuung der Fellows.",
    image: fraunhofer,
    link: "https://www.wi.fit.fraunhofer.de/",
  },
];

export default function Collaboration() {
  return (
    <section id="collaboration">
      <div className="content grid p-grid-standard xs:grid-gap-20 md:grid-gap-50  lg:grid-gap-100 body-1">
        {collaborations.map((collaboration, index) => (
          <div className="col-12 lg:col-6" key={index}>
            <img
              src={collaboration.image}
              className="pb-20 img-h-60"
              alt={collaboration.alt}
            />
            <br />
            <p className="fw-semi-bold pb-10">{collaboration.title}</p>
            <p className="pb-20">
              {collaboration.description}
              <a
                href={collaboration.link}
                target="_blank"
                rel="noreferrer"
                className="tc-blue fw-semi-bold"
              > Mehr erfahren</a>
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}

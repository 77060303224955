import React from "react";
import DefautLayout from "../layouts/default";
import * as AboutPage from "../components/screens/about";
import "../assets/sass/index.scss";

export default function About() {
  return (
    <DefautLayout title="Über uns">
      <div id="about">
        <div className="content-wrapper">
          <AboutPage.Header />
          <AboutPage.Collaboration />
          <AboutPage.Hero />
          <AboutPage.Introduction />
          {/* <AboutPage.LocalHeader /> */}
          {/* <AboutPage.Team /> */}
        </div>
      </div>
    </DefautLayout>
  );
}

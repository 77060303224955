import Afsaneh from "../../../../assets/img/components/team/afsaneh.webp";
import Annina from "../../../../assets/img/components/team/annina.webp";
import Basti from "../../../../assets/img/components/team/basti.webp";
import Bela from "../../../../assets/img/components/team/bela.webp";
import Daniel from "../../../../assets/img/components/team/daniel.webp";
import Lorenz from "../../../../assets/img/components/team/lorenz.webp";
import Marcus from "../../../../assets/img/components/team/marcus.webp";
import Melike from "../../../../assets/img/components/team/melike.webp";
import Michi from "../../../../assets/img/components/team/michi.webp";
import Natt from "../../../../assets/img/components/team/natt.webp";
import Philip from "../../../../assets/img/components/team/philip.webp";
import Steffen from "../../../../assets/img/components/team/steffen.webp";
import Tobias from "../../../../assets/img/components/team/tobias.webp";

let members = [
    {
        name: "Afsaneh Asaei",
        title: "Head of Artificial Intelligence",
        shortDescription: `Ich helfe unseren Teams Künstliche Intelligenz gezielt einzusetzen.`,
        pic: Afsaneh,
        link: "https://www.linkedin.com/in/afsaneh-asaei-490b6621",
        buttonIconColor: "blue",
    },
    {
        name: "Daniel Bedö",
        title: "Head of Software Engineering",
        shortDescription:
            "Agil heißt nicht planlos. Durch mein Mentoring wird die Planung der nächsten technischen Schritte zum Kinderspiel.",
        pic: Daniel,
        link: "https://www.linkedin.com/in/danielbedo/",
        buttonIconColor: "red",
    },
    {
        name: "Natthagorn Bunnet",
        title: "Product Manager Operations",
        shortDescription: `Ich stelle einen reibungslosen Ablauf des Programms sicher und organisiere tolle Events für unsere Teilnehmenden. `,
        pic: Natt,
        link: "https://www.linkedin.com/in/natthagorn-bunnet-b77080142/",
        buttonIconColor: "black",
    },
    {
        name: "Lorenz Hutterer",
        title: "Lead Operations & Business Development",
        shortDescription: `Ich hinterfrage stets den generierten Nutzen der Produkte für die Zielgruppe. `,
        pic: Lorenz,
        link: "https://www.linkedin.com/in/lorenz-hutterer-2a1a021a5",
        buttonIconColor: "yellow",
    },
    {
        name: "Steffen Kastner",
        title: "Head of Product Management",
        shortDescription: `Ich treibe unsere Teams durch herausfordende und unbequeme Fragen voran.`,
        pic: Steffen,
        link: "https://www.linkedin.com/in/steffen-kastner-177494a9/",
        buttonIconColor: "yellow",
    },
    {
        name: "Tobias Kalkowsky",
        title: "Agile Coach",
        shortDescription: `Ich befähige Teams sich selbst zu organisieren und Probleme in Chancen zu verwandeln.`,
        pic: Tobias,
        link: "https://de.linkedin.com/in/tobias-kalkowsky-21b7a4163",
        buttonIconColor: "blue",
    },
    {
        name: "Bela Sinoimeri",
        title: "Software Engineer",
        shortDescription: `Ich helfe den Teams die technischen Risiken in ihren Lösungen zu identifizieren und zu überwinden.`,
        pic: Bela,
        link: "https://www.linkedin.com/in/bsinoimeri/",
        buttonIconColor: "black",
    },
    {
        name: "Marcus Paeschke",
        title: "Head of Interaction Design",
        shortDescription:
            "Ich vermittle modernste Prototyping Methoden, damit fesselnde Interaktionen zum Leben erweckt werden können.",
        pic: Marcus,
        link: "https://www.linkedin.com/in/marcus-paeschke-66305a55/",
        buttonIconColor: "yellow",
    },
    {
        name: "Bastian Rieder",
        title: "Head of Product Marketing",
        shortDescription: `Ich verbreite über verschiedene Kanäle Wissenswertes über die Digitalschmiede Bayern und motiviere Studierende und Absolvent:innen sich zu bewerben.`,
        pic: Basti,
        link: "https://www.linkedin.com/in/bastian-rieder-marketingmba/",
        buttonIconColor: "black",
    },
    {
        name: "Annina Schaupp",
        title: "Lead Team Development Coach",
        shortDescription: `Ich befähige die Teams in agilen Arbeitsweisen und helfe sie in der täglichen Arbeit gewinnbringend zu verankern.`,
        pic: Annina,
        link: "https://www.linkedin.com/in/annina-schaupp-18a913177/v",
        buttonIconColor: "yellow",
    },
    {
        name: "Michael Stockerl",
        title: "Director Digital Product School",
        shortDescription: `Ich öffne die Türen in unser Netzwerk und helfe Hindernisse aus dem Weg zu räumen.`,
        pic: Michi,
        link: "https://www.linkedin.com/in/michael-stockerl-8584a347/",
        buttonIconColor: "blue",
    },
    {
        name: "Philip Schneider",
        title: "Principal Program Manager GovTech",
        shortDescription: `Ich liebe es Menschen zu vernetzen und spüre neue Herausforderungen auf.`,
        pic: Philip,
        link: "https://de.linkedin.com/in/schneiderphilip",
        buttonIconColor: "blue",
    },
    {
        name: "Melike Bilbey",
        title: "UX Design",
        shortDescription: `Ich unterstütze unsere Teams in ihrem Design Prozess und habe immer ein gutes Auge für Ästhetik.`,
        pic: Melike,
        link: "https://de.linkedin.com/in/melike-bilbey-116612128",
        buttonIconColor: "black",
    },
];
members.sort((m1, m2) => (m1.name > m2.name ? 1 : -1));

export default members;
import React from "react";
import ColumnText from "../../shared/columnText";

export default function Introduction() {
  return (
    <section id="introduction">
      <ColumnText
        nrColumns="2"
        textLeftStyle="headline-1"
        textLeft="Wir stecken unser Herzblut in die Entwicklung neuer digitaler Produkte und Services"
        textRight={
          <>
            <p>
            Das Kernteam aus dem mantro product studio, dem Institutsteil Wirtschaftsinformatik des Fraunhofer FIT und des Forschungsinstituts für Informationsmanagement (FIM) besteht aus einer autonomen Gruppe von <b>Experten in den Rollen, die man typischerweise in erfolgreichen Projekt-Teams</b> wieder findet: Produktmanagement, Design, Software-Entwicklung, Künstliche Intelligenz, Agile Coaching, Marketing, etc.
            </p>
            <p className="pt-20">
              Unsere{" "}
              <strong>Mentoren und Coaches bringen langjährige Erfahrung</strong> aus
              Projekt-Teams, Startups, Industrie und Forschung mit. Zusammen begleiten, unterstützen und helfen wir den Teilnehmerinnen und Teilnehmern ein nutzerzentriertes Prototyps in einem agilen Team zu entwickeln.
            </p>
          </>
        }
      />
    </section>
  );
}

import React from "react";
import SharedHeader from "../../shared/header";
import header from "../../../assets/img/components/header/mantro-office.jpg";
export default function Header() {
  return (
    <section id="header">
      <SharedHeader
        image={header}
        imageAltText="Bild von dem Bürogebäude der mantro GmbH"
        classNameImage="header-image"
        align="right"
        titlesize="display-2"
        titlecolor="blue"
        title={<>Der Fokus auf Innovation</>}
        text={
          <>
            einigt unser buntes Ökosystem und schafft den idealen Nährboden für agile Produktentwicklung. 
          </>
        }
        textsize="body-3"
      />
    </section>
  );
}

import React from "react";
import BlueHero from "../../shared/bluehero";
import hero from "../../../assets/img/components/hero/blueHero.webp";

export default function Hero() {
  return (
    <section id="blueHero">
      <BlueHero
        image={hero}
        alt="zusammen"
        lineStyle="blue-hero-text-left p-standard headline-1"
        line="Zusammen kuratieren wir ein engagiertes Netzwerk, das Talente, Behörden und Digitalwirtschaft zusammenbringt."
      />
    </section>
  );
}
